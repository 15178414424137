/* ABRANGENCIA */

.abrangencia {
    display: flex;
    flex-direction: column;
    background-color: var(--cdm-branco);
    padding: 2rem 0;
}

.abrangencia__titulo {
    margin: 0 2rem 2rem 2rem;
}

.abrangencia__titulo h2 {
    color: var(--cdm-secundaria);
    font-size: 1.5rem;
    font-weight: 700;
    text-decoration: underline;
    text-underline-offset: 0.3rem;
    text-decoration-color: var(--cdm-principal);
    text-align: center;
}

.cidades {
    display: flex;
    flex-wrap: wrap;
    margin: 0 2rem;
    justify-content: center;
    gap: 2rem;
}

.cidades__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    flex: 1 1 40%;
    max-width: 45%;
    box-shadow: 3px 3px 0px 0px var(--cdm-secundaria);
    border-radius: 0.5rem;
    background-color: var(--cdm-principal);
    padding: 1rem 0;
}

.cidades__item p {
    font-size: 1rem;
    font-weight: 700;
    margin-top: 0.5rem;
    color: var(--cdm-branco);
}

.cidades__item img {
    width: 40%;
}

@media screen and (min-width: 768px) {

    .abrangencia__titulo h2 {
        font-size: 1.7rem;
        text-underline-offset: 0.4rem;
    }

    .cidades {
        margin: 0 2rem;
    }

    .cidades__item {
        flex: 1 1 30%;
        max-width: 30%;
    }

    .cidades__item p {
        font-size: 1.1rem;
    }

    .cidades__item img {
        width: 25%;
    }
}

@media screen and (min-width: 1025px) {
    .abrangencia__titulo h2 {
        font-size: 2.3rem;
        text-underline-offset: 0.4rem;
        margin: 1rem 0 3rem 0;
    }

    .cidades {
        margin: 0 3rem;
    }

    .cidades__item {
        flex: 1 1 20%;
    }

    .cidades__item:last-child {
        max-width: 25%;
    }
    
}

@media screen and (min-width: 1440px) {
    .cidades {
        margin: 0 5rem;
    }

    .cidades__item {
        flex: 1 1 22%;
    }
}
