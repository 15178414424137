/* APP */
.aplicativo {
    margin-top: 2rem;
    background-color: var(--cdm-principal);
    font-size: 1rem;
    line-height: 1.4rem;
    color: var(--cdm-secundaria);
}

.aplicativo_apresentacao {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 2rem 0;
    background-color: var(--cdm-secundaria);
}

.aplicativo_titulo {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.aplicativo_titulo-elementos {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.aplicativo_titulo h1 {
    font-size: 2rem;
    font-weight: 700;
    line-height: 2rem;
    text-align: initial;
    color: var(--cdm-branco);
    margin: 0 1.5rem;
}

.aplicativo_titulo img {
    width: 100%;
    height: auto;
}

.aplicativo_titulo h1 strong {
    color: var(--cdm-principal);
}

.aplicativo_apresentacao p {
    color: var(--cdm-branco);
    margin: 0 1.5rem;
}

.aplicativo_apresentacao p strong {
    color: var(--cdm-principal);
}

.div-separate-app {
    width: 100%;
    text-align: center;
    margin: 20px 0;
}

.style-div-separate-app::before,
.style-div-separate-app::after {
    content: '';
    display: flex;
    width: 5rem;
    height: 0.12rem;
    margin: 0 auto;
    background-color: var(--cdm-principal);
}

.container-download_botoes p {
    text-align: center;
    font-weight: 700;
    margin-bottom: 1rem;
}

.download_botoes {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
    width: auto;
    gap: 2rem;
}

.aplicativo_download {
    width: 10rem;
    height: auto;
    object-fit: contain;
    cursor: pointer;
}

.aplicativo_steps-descricao {
    padding: 2rem 1.5rem;
}

.aplicativo_steps {
    padding: 1.5rem;
    border-right: 3px solid var(--cdm-secundaria);
    border-bottom: 3px solid var(--cdm-secundaria);
    border-top: 3px solid var(--cdm-secundaria);
    border-radius: 1rem;
    box-shadow: 0.5rem 0.5rem 0.5rem 0 rgba(0, 0, 0, 0.2);
    margin-bottom: 1.5rem;
    background-color: var(--cdm-branco);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
}

.aplicativo_descricao {
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
}

.aplicativo_descricao h3 {
    font-size: 1.5rem;
    line-height: 1.2;
    font-weight: 500;
}

.aplicativo_descricao h3 strong {
    font-weight: 700;
}

.aplicativo_descricao h4 {
    font-size: 3rem;
    font-weight: 700;
    line-height: 1;
}

.aplicativo_descricao h5 {
    font-size: 1.7rem;
    font-weight: 700;
    line-height: 1;
}

.aplicativo_descricao strong {
    color: var(--cdm-principal);
    font-weight: 600;
}

.aplicativo_gocare {
    text-align: center;
}

.aplicativo_gocare img {
    width: 350px;
    height: auto;
}

.aplicativo_descricao ul {
    list-style-type: none;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.aplicativo_descricao li::before {
    content: '•';
    color: var(--cdm-principal);
    font-weight: bold;
    font-size: 1.3rem;
    display: inline-block;
    width: 20px;
    margin-left: -20px;
}


@media screen and (min-width: 768px) {
    .aplicativo {
        margin-top: 2.9rem;
        background-color: var(--cdm-branco);
    }

    .aplicativo_titulo h1 {
        font-size: 2.3rem;
        line-height: 3rem;
        margin-left: 5rem;
    }


    .aplicativo_apresentacao p {
        margin: 0 3rem;
    }


    .aplicativo_titulo-elementos {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        background-color: var(--cdm-secundaria);
        overflow: hidden;
    }

    .aplicativo_titulo img {
        width: 100%;
        height: auto;
    }

    .aplicativo_steps-descricao {
        padding: 2rem 5rem;
        display: flex;
        flex-direction: column;
        gap: 5rem;
    }

    .container-download_botoes p {
        margin-bottom: 1rem;
    }

    .aplicativo_steps {
        padding: 1.5rem;
        border-right: none;
        border-bottom: none;
        border-top: none;
        border-radius: 0;
        box-shadow: none;
        margin-bottom: 1.5rem;
        background-color: var(--cdm-branco);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 1.5rem;
    }

    .aplicativo_descricao {
        display: flex;
        flex-direction: column;
        gap: 0.7rem;
    }

    .aplicativo_gocare img {
        width: 350px;
        height: auto;
    }

}

@media screen and (min-width: 1025px) {
    .aplicativo {
        margin-top: 7.9rem;
        font-size: 1.1rem;
    }

    .aplicativo_apresentacao p {
        margin: 0 5rem;
    }

    .aplicativo_steps {
        gap: 3rem;
    }

    .aplicativo_gocare img {
        width: 450px;
        height: auto;
    }

    .container-download_botoes p {
        margin-bottom: 1rem;
    }

}

@media screen and (min-width: 1440px) {
    .aplicativo {
        margin-top: 8.7rem;
        font-size: 1.2rem;
        line-height: 1.2;
    }

    .aplicativo_titulo-elementos img {
        width: 100%;
        height: auto;
    }


    .aplicativo_apresentacao p {
        margin: 0 15rem;
    }

    .aplicativo_steps-descricao {
        padding: 2rem 15rem;
    }

    .aplicativo_steps {
        gap: 3rem;
    }

    .aplicativo_gocare img {
        width: 500px;
    }

    .container-download_botoes p {
        margin-bottom: 1rem;
    }
}