.citymodal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    padding: 0;
}

.citymodal-content {
    background: var(--cdm-branco);
    width: 100%;
    max-height: 90vh;
    overflow: hidden;
    border-radius: 8px;
    padding: 0;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

.citymodal-content h2 {
    font-size: 1.2rem;
    color: var(--cdm-principal);
    font-weight: 700;
    text-align: center;
}

.close-button {
    align-self: flex-end;
    background: none;
    border: none;
    font-size: 1rem;
    cursor: pointer;
    color: var(--cdm-secundaria);
    font-weight: 600;
}

.citymodal-table-container {
    margin-top: 30px;
    overflow-y: auto;
    max-height: 60vh;
}

.citymodal-overlay table {
    width: 100%;
    border-collapse: collapse;
}

.citymodal-overlay th,
td {
    padding: 0.5rem;
    text-align: left;
    border-bottom: 1px solid var(--cdm-branco);
    font-size: 1rem;
}

.citymodal-overlay th {
    font-weight: 600;
    font-size: 1.1rem;
}

.citymodal-overlay thead {
    position: sticky;
    top: 0;
    background: var(--cdm-principal);
}

.especialidade {
    font-weight: 600;
}



@media screen and (min-width: 768px) {
    .citymodal-content {
        width: 90%;
        max-width: 800px;
        padding: 1.4rem;
    }

    .close-button {
        font-size: 1rem;
    }

    .citymodal-table-container {
        margin-top: 1.4rem;
        max-height: 70vh;
        line-height: 1.1;
    }
}

@media screen and (min-width: 1025px) {
    .citymodal-content {
        max-width: 1000px;
    }

    .citymodal-table-container {
        margin-top: 1.4rem;
        max-height: 80vh;
    }
}

@media screen and (min-width: 1440px) {
    .citymodal-content {
        max-width: 1350px;
    }

    .citymodal-table-container {
        margin-top: 1.4rem;
        max-height: 95vh;
    }
}