/* PLANOS */
.page__plans-section {
    padding-top: 3rem;
    background-color: var(--cdm-principal);
}

.page__plans-container {
    display: flex;
    flex-direction: column;
}

.page__plans-text {
    color: var(--cdm-secundaria);
    margin: 2rem 1.5rem;
}

.page__plans-text h2 {
    font-weight: 500;
    font-size: 1.8rem;
    text-align: center;
    margin: 2rem 0;
}

.page__plans-text strong {
    font-weight: 700;
    color: var(--cdm-branco);
}

.modal__container {
    padding: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
}

.modal__container p {
    color: var(--cdm-branco);
    line-height: 1.2;
}

.modal__container button {
    border: 2px solid var(--cdm-branco);
    padding: 0.5rem;
    font-size: 1rem;
    font-weight: 700;
    color: var(--cdm-branco);
    background-color: var(--cdm-secundaria);
    border-radius: 10px;
}

.modal__container button:hover {
    background-color: var(--cdm-cinza);
    cursor: pointer;
}

@media screen and (min-width: 768px) {
    .page__plans-section {
        padding-top: 6rem;
    }

    .modal__container {
        margin: 0 auto;
        max-width: 600px;
    }
}

@media screen and (min-width: 1025px) {
    .page__plans-section {
        padding-top: 9rem;
    }
}

@media screen and (min-width: 1440px) {
    .page__plans-section {
        padding-top: 10.5rem;
    }
    
}