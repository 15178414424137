.header__bottom, .ocultar {
    display: none;
}

@media screen and (min-width: 1025px) {
    .header__bottom {
        display: flex;
        background-color: var(--cdm-secundaria);
        padding: 1.2rem;
    }

    .bottom__menu__nav {
        margin: auto;
    }

    .bottom__menu__ul {
        display: flex;
        gap: 2rem;
        list-style: none;
        margin: 0;
        padding: 0;
        box-shadow: var(--cdm-shadow);
    }

 
    .bottom__menu__li {
        color: var(--cdm-principal);
        transition: background-color 0.3s ease, color 0.3s ease;
    }

    
    .bottom__header__link {
        display: block;
        text-decoration: none;
        color: var(--cdm-branco);
        max-width: 15rem;
        transition: color 0.3s ease;
    }

    /* HOVER */
    .bottom__menu__li:hover,
    .bottom__hover:hover {
        color: var(--cdm-branco);
    }

    .bottom__header__link.active,
    .bottom__header__link:hover {
        font-weight: 700;
        text-decoration: overline;
        text-decoration-color: var(--cdm-principal);
        color: var(--cdm-branco);
    }
}