.modal-hospital-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-hospital-container {
    background-color: white;
    padding: 20px 30px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
    max-width: 400px;
    width: 100%;
    animation: slideDown 0.3s ease;
    position: relative;
  }
  
  @keyframes slideDown {
    from {
      transform: translateY(-50px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  .modal-hospital-container h2 {
    font-size: 24px;
    margin-bottom: 15px;
    font-weight: 700;
    color: var(--cdm-principal);
  }
  
  .modal-hospital-container p {
    font-size: 16px;
    color: var(--cdm-secundaria);;
    margin-bottom: 20px;
    line-height: 1.4;
  }
  
  .modal-hospital-container span {
    font-weight: 700;
    color: var(--cdm-principal);;
  }
  
  .modal-hospital-image {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
    margin-bottom: 15px;
  }
  
  .modal-hospital-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    font-size: 18px;
    font-weight: bold;
    color: #555;
    cursor: pointer;
    transition: color 0.3s ease;
  }
  
  .modal-hospital-close-button:hover {
    color: var(--cdm-principal);
  }
  
  @media (max-width: 480px) {
    .modal-hospital-container {
      padding: 15px 20px;
    }
  
    .modal-hospital-container h2 {
      font-size: 20px;
    }
  
    .modal-hospital-container p {
      font-size: 14px;
    }
  }
  