/* MISSION */

.valores__info {
    background-color: var(--cdm-secundaria);
    width: 100%;
    box-shadow: var(--cdm-shadow);
}

.valores__info-list {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
}

.valores__info-item {
    color: var(--cdm-branco);
    text-align: center;
    margin: 2rem;
    line-height: 1.5rem;
}


.info-item-titulo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}


.info-item-titulo img {
    width: 15%;
    filter: invert(100%) brightness(2);
}

.info-item-titulo h4 {
    font-size: 1.23rem;
    font-weight: 700;
    margin-bottom: 1rem;
}

.info-item-titulo p {
    margin-bottom: 0.7rem;
}


@media screen and (min-width: 768px) {
    .valores__info-list {
        padding: 2rem 0;
        margin: 0 3rem;
    }

    .valores__info-item {
        margin: 1rem;
        line-height: 1.3rem;
    }


    .info-item-titulo {
        gap: 0.5rem;
    }


    .info-item-titulo img {
        width: 9%;
    }
}

@media screen and (min-width: 1025px) {
    .valores__info-list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 2rem;
    }

    .info-item-titulo img {
        width: 15%;
    }

}

@media screen and (min-width: 1440px) {
    .valores__info-list {
        margin: 0 15rem;
    }
}