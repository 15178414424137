/* Faq.css */
.faq-container {
  background: var(--cdm-principal);
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.questions__title {
  padding: 2rem 0 1rem 0;
  width: 100%;
  background-color: var(--cdm-secundaria);
}

.questions__title h3 {
  color: var(--cdm-branco);
  font-size: 1.5rem;
  font-weight: 700;
  text-decoration: underline;
  text-underline-offset: 0.3rem;
  line-height: 2rem;
  text-decoration-color: var(--cdm-principal);
  text-align: center;
  margin: 0 2rem;
}

.faq-section {
  padding: 1.5rem;
}

.faq-item {
  background: #f9f9f9;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 15px;
  transition: background 0.3s ease-in-out;
}

.faq-item:hover {
  background: #f1f1f1;
}

.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 500;
  cursor: pointer;
  color: var(--cdm-secundaria);
}

.faq-question p {
  margin: 0;
  font-size: 1rem;
  line-height: 1.2;
}

.arrow {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--cdm-principal);
  transition: transform 0.3s ease-in-out, color 0.3s;
}

.arrow.open {
  transform: rotate(180deg);
  color: var(--cdm-principal);
}

.faq-icon {
  margin-right: 8px;
  font-size: 18px;
}

.faq-answer {
line-height: 1.5;
}

.faq-answer a {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
}

.faq-answer a:hover {
  text-decoration: underline;
}

.faq-answer {
  padding: 10px 15px;
  font-size: 1rem;
  color: var(--cdm-secundaria);
  background: #fff;
  border-left: 4px solid var(--cdm-principal);
  margin-top: 5px;
  border-radius: 5px;
}

/* Animações */
.faq-enter {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
}

.faq-enter-active {
  max-height: 500px;
  opacity: 1;
}

.faq-exit {
  max-height: 500px;
  opacity: 1;
  overflow: hidden;
  transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
}

.faq-exit-active {
  max-height: 0;
  opacity: 0;
}

@media screen and (min-width: 768px) {

  .questions__title h3 {
      font-size: 1.7rem;
      text-underline-offset: 0.4rem;
      line-height: 2rem;
      padding-top: 0.5rem;
  }

  .faq-section {
      padding: 2rem 5rem;
  }
}

@media screen and (min-width: 1025px) {


  .questions__title h3 {
      font-size: 1.8rem;
      text-underline-offset: 0.4rem;
      line-height: 2rem;
      padding-top: 0.5rem;
  }

  .faq-section {
    padding: 2rem 15rem;
}
}

@media screen and (min-width: 1440px) {
 
  .questions__title h3 {
      font-size: 2.3rem;
      text-underline-offset: 0.5rem;
      line-height: 2rem;
      padding-top: 1rem;
  }
  .faq-section {
    padding: 2rem 25rem;
}
}