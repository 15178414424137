/* Blocos individuais */
.boleto-section {
    margin-top: 3.9rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: var(--cdm-principal);
    color: #fff;
    height: auto;
    box-sizing: border-box;
}

.boleto-container {
    display: flex;
    flex-direction: column;
    gap: 1em;
    width: 100%;
    max-width: 1200px;
}

.boleto-block {
    background-color: var(--cdm-branco);
    padding: 20px;
    text-align: center;
    border-radius: 8px;
    box-shadow: var(--cdm-shadow);
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

/* Títulos das seções */
.boleto-title {
    font-size: 24px;
    color: var(--cdm-principal);
    margin-bottom: 10px;
    text-transform: uppercase;
    font-weight: 700;
}

/* Textos */
.boleto-text {
    font-size: 16px;
    line-height: 1.6;
    color: var(--cdm-secundaria);
    margin-bottom: 20px;
    text-align: center;
}

/* Contatos (telefone e e-mail) */
.boleto-contact-block {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 20px 0;
}

/* Link para Solicitar Boleto */
.boleto-link {
    display: inline-block;
    font-size: 16px;
    color: #fff;
    background-color: var(--cdm-secundaria);
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.boleto-link:hover {
    background-color: var(--cdm-botao-hover);
}

.boleto-contact {
    color: var(--cdm-secundaria);
}

.boleto-icon {
    font-size: 1.2em;
    color: var(--cdm-principal);
}

/* Ajustes de responsividade continuam os mesmos */

/* Telas maiores: 3 colunas */
@media (min-width: 1024px) {
    .boleto-section {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100vh;
        padding: 0 4rem;
        box-sizing: border-box;
    }

    .boleto-container {
        margin: 0;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1em;
    }

    .boleto-block {
        border: none;
        border-right: 2px solid var(--cdm-cinza-shadow);
    }

    .boleto-block:last-child {
        border-right: none;
    }
}