.card-planos-container {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.card-planos__adesao,
.card-planos__empresa,
.card-planos__indfam {
    display: flex;
    flex-direction: column;
}

.card-planos__text {
    font-weight: 500;
    margin: 0 1.5rem 2rem 1.5rem;
}

.card-planos__text h3 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
}

.card-planos__text p {
    line-height: 1.2rem;
}

.card-planos__text strong {
    font-weight: 700;
}

.div-separate {
    width: 100%;
    text-align: center;
    margin: 20px 0;
}

.style-div-separate::before,
.style-div-separate::after {
    content: '';
    display: flex;
    width: 5rem;
    height: 0.15rem;
    margin: 0 auto;
}

.card-planos__cards {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: stretch;
    margin: 0 1.5rem;
}

.card {
    display: flex;
    flex-direction: column;
    background-color: var(--cdm-secundaria);
    color: var(--cdm-branco);
    padding: 2rem;
    align-items: center;
    border-radius: 1.5rem;
    gap: 1rem;
    justify-content: space-between;
    box-sizing: border-box;
    flex: 1;
}

.bg-plans {
    background-image: url('../../../../assets/img-main/bg-plans.webp');
    background-position: center;
    background-size: cover;
}

.plan-name {
    font-size: 1.5rem;
    font-weight: 700;
    text-align: center;
}

.plan-starting-price,
.age-group {
    font-weight: 600;
}

.price {
    font-weight: 700;
    font-size: 2.5rem;
    text-decoration: underline;
    text-underline-offset: 0.2rem;
    text-decoration-color: var(--cdm-principal);
}

.plan__room {
    text-align: center;
    font-size: 0.55rem;
    font-weight: 500;
    line-height: 1rem;
}

.plan__room strong {
    font-weight: 700;
    font-size: 0.9rem;
}

.hospitals {
    display: flex;
    flex-direction: column;
    line-height: 1.3rem;
    align-items: center;
    text-align: center;
    font-weight: 500;
    gap: 0.5rem;
    margin-top: 3rem;
}

.quote-button-container {
    text-align: center;
    margin-top: auto;
}

.quote-button {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: var(--cdm-branco);
    font-size: 0.9rem;
    font-weight: 700;
    gap: 0.6rem;
    padding: 0.5rem 1rem;
    background-color: var(--cdm-principal);
    border: 2px solid var(--cdm-branco);
    border-radius: 2rem;
}

.quote-button img {
    max-width: 1.2rem;
}

.card-planos__adesao,
.card-planos__empresa,
.card-planos__indfam {
    padding: 5rem 0;
}

/* CARD ADESÃO */
.card-planos__adesao {
    background-color: var(--cdm-secundaria);
}

.card-planos__adesao .card-planos__text {
    color: var(--cdm-cinza-shadow);
}

.card-planos__adesao .card-planos__text strong {
    font-weight: 700;
    color: var(--cdm-principal);
}

.card-planos__adesao .style-div-separate::before,
.card-planos__adesao .style-div-separate::after {
    background-color: var(--cdm-principal);
}

.adesao-card {
    background-color: var(--cdm-cinza-shadow);
    color: var(--cdm-secundaria);
}

.card-planos__adesao .quote-button {
    background-color: var(--cdm-principal);
    border: 2px solid var(--cdm-secundaria);
}

/* CARD EMPRESARIAL */
.card-planos__empresa {
    background-color: var(--cdm-principal);
}

.plan-empresa__text {
    color: var(--cdm-secundaria);
}

.card-planos__empresa .card-planos__text strong {
    font-weight: 700;
    color: var(--cdm-branco);
}

.card-planos__empresa .style-div-separate::before,
.card-planos__empresa .style-div-separate::after {
    background-color: var(--cdm-branco);
}

/* CARD FAMILIAR/INDIVIDUAL */
.card-planos__indfam {
    background-color: var(--cdm-cinza-shadow);
}

.card-planos__indfam .card-planos__text {
    color: var(--cdm-secundaria);
}

.card-planos__indfam .card-planos__text strong {
    font-weight: 700;
    color: var(--cdm-principal);
}

.card-planos__indfam .style-div-separate::before,
.card-planos__indfam .style-div-separate::after {
    background-color: var(--cdm-secundaria);
}

.indfam-card {
    background-color: var(--cdm-principal);
    color: var(--cdm-branco);
}

.card-planos__indfam .card-planos__cards .card .price {
    text-decoration-color: var(--cdm-secundaria);
}

.card-planos__indfam .quote-button {
    border: 2px solid var(--cdm-branco);
    background-color: var(--cdm-secundaria);
}

@media screen and (min-width: 600px) {
    .card-planos__cards {
        flex-direction: row;
        justify-content: space-between;
        gap: 0;
    }

    .card,
    .card-planos__cards {
        margin: 0 0.75rem;
    }
}

@media screen and (min-width: 768px) {

    .card-planos__text {
        margin: 0 4rem 2rem 4rem;
    }

    .card,
    .card-planos__cards {
        margin: 0 2rem;
    }
}

@media screen and (min-width: 1440px) {
    .card-planos__text {
        margin: 0 15rem 2rem 15rem;
    }


    .card {
        margin: 0 auto;
        max-width: 30%;
    }
    .card-planos__cards {
        margin: 0 15rem;
    }
}