.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
  }
  
  .modal-content {
    background: var(--cdm-branco);
    padding: 1.2rem;
    border-radius: 8px;
    max-width: 600px;
    width: 100%;
    position: relative;
  }
  
  .modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1rem;
    cursor: pointer;
    align-items: center;
    font-weight: 700;
  }

  .modal-content h2 {
    font-size: 1.2rem;
    font-weight: 700;
    color: var(--cdm-principal);
    text-align: center;
  }

  .modal-content p {
    font-size: 0.9rem;
    margin-top: 1rem;
  }
  
  .modal-overlay table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 1.3rem;
  }


  .modal-overlay th, td {
    padding: 0.7rem;
    text-align: left;
    border-bottom: 1px solid var(--cdm-cinza-claro);
    font-size: 1rem;
    font-weight: 500;
  }
  
  .modal-overlay th {
    background-color: var(--cdm-principal);
    font-weight: 700;
  }
  