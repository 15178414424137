.city-card {
    padding: 1.5rem;
    border-right: 3px solid var(--cdm-secundaria);
    border-bottom: 3px solid var(--cdm-secundaria);
    border-top: 3px solid var(--cdm-secundaria);
    border-radius: 1rem;
    box-shadow: 0.5rem 0.5rem 0.5rem 0 rgba(0, 0, 0, 0.2);
    background-color: var(--cdm-bg-benefits);
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 1rem;
}

.city-card h3 {
    font-size: 1.3rem;
    font-weight: 700;
    line-height: 1.1;
    color: var(--cdm-branco);
}

.city-card button {
    padding: 0.3rem;
    width: 100%;
    font-size: 0.9rem;
    font-weight: 600;
    background-color: var(--cdm-secundaria);
    color: var(--cdm-branco);
    cursor: pointer;
    border: none;
}

.city-card-button {
    display: none;
}

@media screen and (min-width: 768px) {

    .city-card-button {
        display: block;
    }
}