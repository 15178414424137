/* CARROSSEL */

.carousel-container {
  margin: 0 auto;
}

.carousel-container img {
  width: 100%;
  height: auto;
  cursor: pointer;
}

.custom-swiper .swiper-button-next::after,
.custom-swiper .swiper-button-prev::after {
  font-size: 1.5rem;
  font-weight: 700;
  width: 0.8rem;
  color: var(--cdm-cinza);
}


.custom-swiper .swiper-pagination-bullet {
  background: var(--cdm-cinza-shadow);
  opacity: 1;
}

.custom-swiper .swiper-pagination-bullet-active {
  background: var(--cdm-secundaria); 
}
