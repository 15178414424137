/* Estilos comuns */
.wpp__button {
  position: fixed;
  z-index: 1000;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 7rem;
}

.wpp__button a {
  color: var(--cdm-preto);
  text-decoration: none;
  font-weight: 700;
  line-height: 1rem;
  font-size: 0.8rem;
}

.wpp-icon {
  width: 3.5rem;
  height: auto;
  margin-left: 0.2rem;
  animation: pulse 1s infinite;
}

.button-text {
  text-align: center;
  background-color: var(--cdm-branco);
  padding: 0.3rem;
  border: 1px solid var(--cdm-form-confirmation);
  border-radius: 0 0.5rem 0.5rem 0;
}

.wpp__button a:hover {
  text-decoration: underline;
  text-decoration-color: var(--cdm-principal);
}

.wpp__button:hover .wpp-icon {
  transform: scale(1.1);
  transition: transform 0.3s ease;
}

@media screen and (min-width: 768px) {
  .wpp__button {
    bottom: 6rem;
    right: -0.1rem;
  }

  .wpp__button a {
    font-size: 1rem;
  }

  .button-text {
    padding: 0.4rem;
    border-radius: 0.5rem 0 0 0.5rem;
  }

  .wpp-icon {
    width: 4rem;
    margin-left: 0.5rem;
  }
}

@media screen and (min-width: 1024px) {
  .wpp-icon {
    width: 4rem;
    margin-left: 0.6rem;
  }
}


@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
