.info-page {
    padding-top: 4rem;
    text-align: center;
    background-color: var(--cdm-secundaria);
    height: 92vh;
}

.info-page__img {
    width: 100%;
}

.info-page h1 {
    display: none;
    font-size: 1.5rem;
    font-weight: 700;
}

.info-page p {
    line-height: 1.3;
}

.info-page h1,
.info-page p {
    color: var(--cdm-branco);
    margin-top: 2rem;
    padding: 0 1.5rem;
}

.contato__infopage {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    gap: 2rem;
}

.contato__infopage-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contato__infopage-container p {
    font-weight: 700;
    margin-top: 0.4rem;
}

.contato__infopage-container img {
    width: 40px;
    margin-bottom: 0.5rem;
}

.info-page_button button {
    border: 2px solid var(--cdm-branco);
    background-color: var(--cdm-principal);
    color: var(--cdm-branco);
    font-weight: 700;
    border-radius: 5px;
    margin-top: 2rem;
    padding: 0.7rem 1.4rem;
    font-size: 1rem;
    cursor: pointer;
}

.info-page_button button:hover {
    background-color: var(--cdm-cinza);
}

@media screen and (min-width: 768px) {
    .info-page {
        padding-top: 5rem;
    }

    .info-page h1 {
        display: block;
        font-size: 1.7rem;
    }

    .info-page p {
        line-height: 1.3;
    }

    .info-page h1,
    .info-page p {
        padding: 0 5rem;
        display: none;
    }

    .contato__infopage {
        display: none;
    }
}

@media screen and (min-width: 1025px) {
    .info-page {
        padding-top: 10rem;
    }
}

@media screen and (min-width: 1440px) {
    .info-page {
        padding: 10.7rem 0 8rem 0;
        height: 92vh;
    }

}