/* ABOUT */

.about__container {
    display: flex;
    flex-direction: column;
    margin-top: 4rem;
}

.about__banner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    overflow: hidden;
    position: relative;
}

.about__banner img {
    width: 100%;
}

@media screen and (max-width: 767px) {
    .about__banner img {
        content: url('../../../assets/img-main/family-mobile.webp');
    }
}

@media screen and (min-width: 768px) {
    .about__container {
        margin-top: 4.9rem;
    }
}

@media screen and (min-width: 1025px) {
    .about__container {
        margin-top: 9.7rem;
    }
}

@media screen and (min-width: 1440px) {
    .about__container {
        margin-top: 10.5rem;
    }
}
