/* REDES */
.redes-container {
  background-color: var(--cdm-secundaria);
  min-height: 95vh;
  padding-bottom: 2rem;
}

.redes-description {
  padding: 6rem 1.5rem 0;
  color: var(--cdm-branco);
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.redes-description h1 {
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
}

.redes-description p {
  line-height: 1.2;
  font-size: 1.2rem;
}

.redes-description span {
  color: var(--cdm-principal);
}

.redes-page {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  justify-content: center;
  padding: 1rem;
}

.redes-description .div-separate {
  width: 100%;
  text-align: center;
  margin: 20px 0;
}


.redes-description .style-div-separate::before,
.redes-description .style-div-separate::after {
  content: '';
  display: flex;
  width: 5rem;
  height: 0.15rem;
  margin: 0 auto;
}

.redes-description .style-div-separate::before,
.redes-description .style-div-separate::after {
  background-color: var(--cdm-principal);
}

.redes-description .note {
  text-align: center;
  font-size: 12px;
}

@media screen and (min-width: 768px) {
  .redes-container {
    padding: 6rem 4rem 2rem 4rem;
  }

  .redes-page {
    flex-direction: row;
    flex-wrap: wrap;
    padding: 1rem 0;
    gap: 2rem;
  }
}

@media screen and (min-width: 1025px) {
  .redes-container {
    padding: 8rem 5rem 2rem 5rem;
  }

  .redes-description h1 {
    font-size: 2.3rem;
  }
}

@media screen and (min-width: 1440px) {
  .redes-container {
    padding: 8rem 10rem 2rem 10rem;
  }

  .redes-description h1 {
    font-size: 3rem;
  }
}

@media screen and (min-width: 1920px) {
  .redes-description p:not(:last-child) {
    padding: 0 15rem;
    ;
  }
}