/* HOME */
.home {
    margin-top: 3.9rem;
}

@media screen and (min-width: 768px) {
    .home {
        margin-top: 4.9rem;
    }
}

@media screen and (min-width: 1025px) {
    .home {
        margin-top: 9.7rem;
    }
}

@media screen and (min-width: 1440px) {
    .home {
        margin-top: 9.9rem;
    }
}